// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-bookademo-js": () => import("./../../../src/pages/bookademo.js" /* webpackChunkName: "component---src-pages-bookademo-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-entrepreneurs-js": () => import("./../../../src/pages/entrepreneurs.js" /* webpackChunkName: "component---src-pages-entrepreneurs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investwithus-js": () => import("./../../../src/pages/investwithus.js" /* webpackChunkName: "component---src-pages-investwithus-js" */),
  "component---src-pages-joinnetwork-js": () => import("./../../../src/pages/joinnetwork.js" /* webpackChunkName: "component---src-pages-joinnetwork-js" */),
  "component---src-pages-partnerwithus-js": () => import("./../../../src/pages/partnerwithus.js" /* webpackChunkName: "component---src-pages-partnerwithus-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-programs-js": () => import("./../../../src/pages/programs.js" /* webpackChunkName: "component---src-pages-programs-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-whoweare-js": () => import("./../../../src/pages/whoweare.js" /* webpackChunkName: "component---src-pages-whoweare-js" */),
  "component---src-pages-whowehelp-js": () => import("./../../../src/pages/whowehelp.js" /* webpackChunkName: "component---src-pages-whowehelp-js" */),
  "component---src-pages-whyxccelerata-js": () => import("./../../../src/pages/whyxccelerata.js" /* webpackChunkName: "component---src-pages-whyxccelerata-js" */)
}

